// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: '/api/',
  ///DEV
  //redirectionURL: "https://portal.mylam.com/",
  //linksUrl: 'http://ddtcweb12.fremont.lamrc.net:8001/',
  //forecast3: 'https://sfsforecast2dev.lamrc.net',
  //forecast4: 'https://sfsforecast2dev.lamrc.net',
  //nonConformances: 'https://nonconformances2dev.lamrc.net',
  //lamMessage: "https://mylamhome2-dev.lamrc.net/home",
  //firstArticle: 'https://firstarticle2-dev.lamrc.net'

  ///QA
  redirectionURL: "https://qa-portal.mylam.com/",
  linksUrl: 'https://qa-portal.mylam.com/',
  forecast3: 'https://sfsforecast2qa.mylam.com',
  forecast4: 'https://sfsforecast2qa.mylam.com',
  nonConformances: 'https://nonconformances2qa.mylam.com',
  lamMessage: "https://mylamhome2-qa.mylam.com/home",
  firstArticle: 'https://firstarticle2-qa.mylam.com'
};
