// DataServices.ts
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '../../environments/environment';
import { JwtHelper } from '../shared/jwt-helper-service';
import { Observable, from } from 'rxjs';
//import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators';
import { FilterModel } from '../model/filter.model';

//import { PORFilterModel } from '../model/PORFilter.model';
import { AuthService } from './auth.service';

@Injectable()
export class DataServices {

  ///shared data for the application
  public PORData: any=[];  
  public downloadData: any;
  public pageNumberString: string;
  public totalPages: number;   
  public user: { role: string, name: string } = { role: "", name: "" };
  public suppliercode: string;
  showApplication: boolean = false;
  private API_URL: string = environment.apiUrl;
  //private API_URL: string = "http://localhost:8673/api/";//;
  
  preloader: boolean;
  extractLoader:boolean;
  tokenValue: any;
  isVendor: boolean;
  role: string;
  vendorId: string;
  sessionId: string;
  //public filterModel: PORFilterModel;
  
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private jwtHelper: JwtHelper,
    private _http: HttpClient,
    private authService: AuthService) {
    //console.log("DataServices initiated ... ");

    //this.filterModel = new PORFilterModel();

    //console.log("DataServices >>> ");
    this.preloader = false;
    this.extractLoader = false;
    //setInterval(() => { this.renewToken(); }, 3000000);
  }

  renewToken() {
    //console.log("renewToken called: >>>>>>>>>>>>>>>>>  ");
    const endpoint = this.API_URL + 'security/auth?sessionid=' + this.jwtHelper.decodeToken(this.getTokenValue()).id;//this.sessionId;

    let headers = this.getHeadersForAuth();
    let options = new RequestOptions({ headers: headers, body: '' });

    var currentdate = new Date();
    var datetime = "Refresh Token: " + currentdate.getDate() + "/"
      + (currentdate.getMonth() + 1) + "/"
      + currentdate.getFullYear() + " @ "
      + currentdate.getHours() + ":"
      + currentdate.getMinutes() + ":"
      + currentdate.getSeconds();

    var newToken = this._http.post(endpoint, {});

    newToken.subscribe(obj => {
      window.localStorage.setItem('token', obj["auth_token"])
      this.authService.setAuthorizationToken(obj["auth_token"]);
      //console.log("Date time:   ", datetime)
    });


  }


  getSessionID(token: string) {
    return this.jwtHelper.decodeToken(token).id;
  }

  getHeadersForAuth(): Headers {
    return new Headers(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'

      });
  }

  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  getTokenValue(): string {
    return this.authService.getAuthorizationToken();    
  }

  getIsVendor(): boolean {
    return this.jwtHelper.decodeToken(this.getTokenValue()).isVendor == 'Y';
  }

  getRole(): string {
    return this.jwtHelper.decodeToken(this.getTokenValue()).userRole;
  }
  getLeadTimeUpdateRole(): Observable<any> {
    return this._http.get(this.API_URL + "leadtimeupdate/GetLeadtimeUpdateRole")

  }

  getVendorId(): string {
    return this.suppliercode;
    //return this.jwtHelper.decodeToken(this.getTokenValue()).supplierCode.trim();
  }

  getSupplierCodeList(): any {
    return this.jwtHelper.decodeToken(this.getTokenValue()).supplierCode.trim().split(',');
  }

  getUser() {
    var _user: { role: string, name: string } = { role: "", name: "" };

    var decodedToken = this.jwtHelper.decodeToken(this.getTokenValue());
    if (decodedToken) {
      _user.role = decodedToken.sapSystem;
      _user.name = decodedToken.userName;
    }

    return _user;
  }

  manageVendorPersisitance(venCode: string, type: string): Observable<any> {
    return this._http.get(this.API_URL + "leadtimeupdate/ManageVendorPersisitance?vendorCode=" + venCode + "&type=" + type)
  }

  updateData(dataToUpdate: any) {
    //this.API_URL + "leadtimeupdate/GetLeadTimeData?" + strParam
    let Params = new HttpParams();   
    let obj = Object.assign({}, dataToUpdate);
    //const endpoint = this.API_URL + 'leadtimeupdate/UpdateData?model=' + JSON.stringify(obj);
    const endpoint = this.API_URL + 'leadtimeupdate/UpdateData';
    //return this._http.post(endpoint, { params: Params });
    //return this._http.post(endpoint + "?model=" + JSON.stringify(obj), {} );
    
    return this._http.post(endpoint, dataToUpdate);
  }  

  getData(filterModel: FilterModel): Observable<any>  {
    this.preloader = true;
    //console.log("filterModel     ", filterModel);
    //http://localhost:8673/api/leadtimeupdate/GetLeadTimeData?
    //return this._http.get("http://localhost:3000/PORData")

    var strParam = "NoOfset=" + filterModel.pageNumber + "&Supplier=" + filterModel.supplierCode + "&PartNo=" + filterModel.partNo + "&Buyer=" + filterModel.buyer + "&SourceCode=" + filterModel.sourceCode + "&SBM=" + filterModel.sbm + "&LeadTime=" + filterModel.leadTime + "&TargetLt=" + filterModel.targetLT + "&Demand=" + filterModel.demand + "&BuyerAction=" + filterModel.action + "&Plant=" + filterModel.plant + "&IsDownload=no"
    return this._http.get(this.API_URL + "leadtimeupdate/GetLeadTimeData?" + strParam)
  }

downloadTrainingFile(fileName: string) {
    
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', this.API_URL + "leadtimeupdate/DownloadHelpFile?fileName=" + fileName);
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getTokenValue());
      xhr.responseType = 'blob';

      xhr.onreadystatechange = function () {
        //this.preloader = false;
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            //file = new Blob([xhr.response], { type: 'application/octet-stream' });
            //var contentType = 'application/x-msdownload';
            var contentType = 'application/pdf';
            var blob = new Blob([xhr.response], { type: 'application/octet-stream' });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      }
      xhr.send();
    });
  }



  getDownloadData(filterModel: FilterModel): Observable<any> {
    this.preloader = true;
    var strParam = "NoOfset=" + filterModel.pageNumber + "&Supplier=" + filterModel.supplierCode + "&PartNo=" + filterModel.partNo + "&Buyer=" + filterModel.buyer + "&SourceCode=" + filterModel.sourceCode + "&SBM=" + filterModel.sbm + "&LeadTime=" + filterModel.leadTime + "&TargetLt=" + filterModel.targetLT + "&Demand=" + filterModel.demand + "&BuyerAction=" + filterModel.action + "&Plant=" + filterModel.plant + "&IsDownload=yes"

    return this._http.get(this.API_URL + "leadtimeupdate/GetLeadTimeData?" + strParam)
  }
  ////////////////////////////////
  getDownloadDataExcel(filterModel: FilterModel) {    
    this.preloader = true;
    var strParam = "NoOfset=" + filterModel.pageNumber + "&Supplier=" + filterModel.supplierCode + "&PartNo=" + filterModel.partNo + "&Buyer=" + filterModel.buyer + "&SourceCode=" + filterModel.sourceCode + "&SBM=" + filterModel.sbm + "&LeadTime=" + filterModel.leadTime + "&TargetLt=" + filterModel.targetLT + "&Demand=" + filterModel.demand + "&BuyerAction=" + filterModel.action + "&Plant=" + filterModel.plant + "&IsDownload=yes"

    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', this.API_URL + "leadtimeupdate/GetLeadTimeExcel?" + strParam, true);
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getTokenValue());
      xhr.responseType = 'blob';

      xhr.onreadystatechange = function () {
        //this.preloader = false;
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      }
      xhr.send();
    });
  }

  getDownloadDataExtractExcel(filterModel: FilterModel) {
    //this.preloader = true;
    this.extractLoader = true;
    var strParam = "NoOfset=" + filterModel.pageNumber + "&Supplier=" + filterModel.supplierCode + "&PartNo=" + filterModel.partNo + "&Buyer=" + filterModel.buyer + "&SourceCode=" + filterModel.sourceCode + "&SBM=" + filterModel.sbm + "&LeadTime=" + filterModel.leadTime + "&TargetLt=" + filterModel.targetLT + "&Demand=" + filterModel.demand + "&BuyerAction=" + filterModel.action + "&Plant=" + filterModel.plant + "&IsDownload=yes"

    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', this.API_URL + "leadtimeupdate/GetExtractExcel?" + strParam, true);
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getTokenValue());
      xhr.responseType = 'blob';

      xhr.onreadystatechange = function () {
        //this.preloader = false;
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      }
      xhr.send();
    });
  }
  /////////////////////

  getDownloadDataExtract(filterModel: FilterModel): Observable<any> {
    //this.preloader = true;
    this.extractLoader = true;
    //console.log("filterModel     ", filterModel);
    var strParam = "NoOfset=" + filterModel.pageNumber + "&Supplier=" + filterModel.supplierCode + "&PartNo=" + filterModel.partNo + "&Buyer=" + filterModel.buyer + "&SourceCode=" + filterModel.sourceCode + "&SBM=" + filterModel.sbm + "&LeadTime=" + filterModel.leadTime + "&TargetLt=" + filterModel.targetLT + "&Demand=" + filterModel.demand + "&BuyerAction=" + filterModel.action + "&Plant=" + filterModel.plant + "&IsDownload=yes"
    return this._http.get(this.API_URL + "leadtimeupdate/GetExportXLData?" + strParam)
  }

  getPlantData(): Observable<any> {
    return this._http.get(this.API_URL + "leadtimeupdate/GetPlantList");    
  }

  getSupplierData(): Observable<any> {
    return this._http.get(this.API_URL + "leadtimeupdate/GetVendorList");  
  }

  getSBMData(): Observable<any> {   
    return this._http.get(this.API_URL + "leadtimeupdate/GetSBMList");  
  }

  getMaterialList(partNo: string): Observable<any> {
    return this._http.get(this.API_URL + "leadtimeupdate/GetPartNo?partno=" + partNo)
  }
}
